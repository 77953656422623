import {
  Controller,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';

import {
  type CommonMedia,
  type ModelsMessageTemplateV2,
} from '@lp-lib/api-service-client/public';

import config from '../../config';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { type Organizer } from '../../types';
import { toMediaDTO } from '../../utils/api-dto';
import { err2s } from '../../utils/common';
import { useCancelConfirmModalStateRoot } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { OrganizerPicker } from '../Organization/OrganizerPicker';
import { type MessageVars } from './MessageCampaign';
import { type MessageLogicFormData } from './MessageLogicEditor';
import {
  MessageTemplateButtonsEditor,
  MessageTemplateButtonsPreview,
} from './MessageTemplateButton';
import {
  MessageTemplateMediaEditor,
  MessageTemplateMediaPreview,
} from './MessageTemplateMedia';
import {
  MessageTemplateTextEditor,
  MessageTemplateTextPreview,
} from './MessageTemplateText';

export interface SendTestFormData {
  recipient: Organizer | null;
}

export function SendTestForm(props: {
  onClose: () => void;
  onConfirm: (data: SendTestFormData) => Promise<void>;
}) {
  const { control, handleSubmit, formState } = useForm<SendTestFormData>({
    defaultValues: {
      recipient: null,
    },
  });

  const {
    call: submit,
    state: { error },
  } = useLiveAsyncCall(async (data: SendTestFormData) => {
    await props.onConfirm(data);
  });

  return (
    <form
      className='w-full px-5 py-7.5 text-white'
      onSubmit={handleSubmit(submit)}
    >
      <header className='text-2xl text-center font-medium'>
        Send Test Message
      </header>

      <main className='mt-10 flex flex-col gap-7.5'>
        <div>
          <h3 className='text-base font-bold mb-1'>Send to</h3>

          <Controller
            control={control}
            name='recipient'
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <OrganizerPicker
                orgId={config.misc.lunaParkOrgId}
                onChange={onChange}
                defaultOrganizer={value || undefined}
                placeholder='Select a member in Luna Park organization'
                className='h-15'
              />
            )}
          />
        </div>
      </main>

      <footer className='mt-10 relative'>
        <div className='w-full mb-1 text-center text-red-500 text-sm'>
          {err2s(error)}
        </div>
        <div className='flex justify-center items-center gap-5'>
          <button
            type='button'
            onClick={props.onClose}
            className='btn-secondary w-40 h-10'
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn-primary w-40 h-10 flex justify-center items-center'
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? 'Sending...' : 'Send'}
          </button>
        </div>
      </footer>
    </form>
  );
}

export function MessageTemplateEditor(props: {
  vars: MessageVars['text'];
  mediaVars: MessageVars['media'];
  setIsUploading: (isUploading: boolean) => void;
}) {
  const { vars, mediaVars, setIsUploading } = props;

  const [confirmationModal, triggerConfirmationModal] =
    useCancelConfirmModalStateRoot();

  const {
    fields: buttons,
    append,
    remove,
    move,
    update,
  } = useFieldArray<MessageLogicFormData, 'template.buttons', 'key'>({
    name: 'template.buttons',
    keyName: 'key',
  });
  const { watch, trigger } = useFormContext<MessageLogicFormData>();
  const template = watch('template');

  const handleSendTest = async () => {
    const result = await trigger('template');
    if (!result) return;

    const mediaMap: Record<string, CommonMedia> = {};
    for (const [key, value] of Object.entries(mediaVars)) {
      if (value) mediaMap[key] = toMediaDTO(value);
    }

    triggerConfirmationModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-160'
          borderStyle='white'
          onClose={p.internalOnCancel}
        >
          <SendTestForm
            onConfirm={async (data) => {
              if (!data.recipient) return;
              await apiService.message.sendTemplate({
                recipientId: data.recipient.uid,
                template,
                vars,
                mediaVars: mediaMap,
              });
              p.internalOnConfirm();
            }}
            onClose={p.internalOnCancel}
          />
        </ModalWrapper>
      ),
    });
  };

  return (
    <section className='w-full'>
      {confirmationModal && (
        <div className='fixed inset-0 overflow-hidden rounded-xl z-10'>
          {confirmationModal}
        </div>
      )}

      <header className='text-2xl font-medium'>Message Template</header>

      <main className='mt-5 w-full flex gap-4'>
        <div className='w-1/2 flex flex-col gap-5'>
          <Controller<MessageLogicFormData, 'template.text'>
            name='template.text'
            render={({ field: { value, onChange }, fieldState }) => (
              <MessageTemplateTextEditor
                vars={vars}
                text={value || ''}
                onChange={onChange}
                error={fieldState.error}
              />
            )}
            rules={{ required: true, minLength: 1, maxLength: 2000 }}
          />

          <Controller<MessageLogicFormData, 'template.media'>
            name='template.media'
            render={({ field: { value, onChange } }) => (
              <MessageTemplateMediaEditor
                media={value}
                vars={mediaVars}
                onChange={onChange}
                setIsUploading={setIsUploading}
              />
            )}
          />

          <MessageTemplateButtonsEditor
            buttons={buttons}
            onAdd={append}
            onDelete={(index) => remove(index)}
            onChange={(index, updates) =>
              update(index, {
                ...buttons[index],
                ...updates,
              })
            }
            onMove={move}
          />
        </div>

        <div className='w-1/2'>
          <div className='mb-2 flex justify-between items-center'>
            <div className='font-bold'>Preview</div>
            <button
              type='button'
              className='btn text-sms text-secondary'
              onClick={handleSendTest}
            >
              Send Test
            </button>
          </div>
          <MessageTemplatePreview
            template={template}
            vars={vars}
            mediaVars={mediaVars}
            className='min-h-72'
          />
        </div>
      </main>
    </section>
  );
}

export function MessageTemplatePreview(props: {
  template: ModelsMessageTemplateV2;
  vars: MessageVars['text'];
  mediaVars: MessageVars['media'];
  className?: string;
}): JSX.Element {
  const { template, vars, mediaVars, className } = props;

  return (
    <div
      className={`w-full p-4 pb-8 border border-secondary rounded-xl ${className}`}
    >
      <div className='flex flex-row items-center gap-2'>
        <img
          src={config.slack.botIconURL}
          alt='luna park'
          className='w-6 h-6 border border-[#2E2E2E]'
        />
        <h3 className='font-bold text-sms'>{config.slack.botUsername}</h3>
      </div>

      <div className='mt-1 w-full pl-8 text-sms text-left flex flex-col gap-5'>
        <MessageTemplateTextPreview text={template.text || ''} vars={vars} />
        <MessageTemplateMediaPreview
          media={template.media}
          mediaVars={mediaVars}
        />
        <MessageTemplateButtonsPreview
          buttons={template.buttons || []}
          vars={vars}
        />
      </div>
    </div>
  );
}
