import { useMemo, useRef } from 'react';
import { type FieldError } from 'react-hook-form';
import { toHTML } from 'slack-markdown';

import { type MessageVars } from './MessageCampaign';
import { MessageTemplateUtils } from './utils';

export function SlackMarkdownHint(props: { className?: string }) {
  return (
    <div
      className={` ${props.className} text-icon-gray text-3xs font-medium text-left`}
    >
      {`*bold* _italics_ ~strike~ :emoji: <https://www.link.com|link>`}
    </div>
  );
}

function MessageVarsHint(props: {
  vars: MessageVars['text'];
  onClick: (key: string, value: string) => void;
  className?: string;
}) {
  return (
    <div
      className={`${props.className} text-icon-gray text-3xs font-medium flex items-center gap-[2px] flex-wrap`}
    >
      <strong>Variables: </strong>
      {Object.entries(props.vars).map(([key, val]) => (
        <button
          key={key}
          type='button'
          title={val}
          onClick={() => props.onClick(key, val)}
          className='btn'
        >{`%${key}%`}</button>
      ))}
    </div>
  );
}

export function MessageTemplateTextEditor(props: {
  text: string;
  vars: MessageVars['text'];
  onChange: (val: string) => void;
  error: FieldError | undefined;
}) {
  const ref = useRef<HTMLTextAreaElement>(null);

  return (
    <div>
      <div className='mb-2 font-bold'>Text</div>
      <textarea
        ref={ref}
        value={props.text}
        onChange={(e) => props.onChange(e.target.value)}
        className={`${
          props.error ? 'field-error' : 'field'
        } h-72 bg-main-layer p-5 mb-0 scrollbar`}
        maxLength={2000}
        minLength={1}
      ></textarea>
      <SlackMarkdownHint className='mt-1' />
      <MessageVarsHint
        className='mt-1'
        vars={props.vars}
        onClick={(key) => {
          if (!ref.current) return;

          const insertedText = `%${key}%`;

          const start = ref.current.selectionStart;
          const end = ref.current.selectionEnd;
          const newText =
            props.text.substring(0, start) +
            `%${key}%` +
            props.text.substring(end, props.text.length);
          props.onChange(newText);
          ref.current.selectionStart = ref.current.selectionEnd =
            start + insertedText.length;
          ref.current.focus();
        }}
      />
    </div>
  );
}

export function MessageTemplateTextPreview(props: {
  text: string;
  vars: MessageVars['text'];
}) {
  const previewHTML = useMemo(() => {
    const translated = MessageTemplateUtils.TranslateText(
      props.text,
      props.vars
    );
    // support for water cooler topics
    const customHtml = translated.replace(
      / *>>> ?(.*)/s,
      `<blockquote class="blockquote">$1</blockquote>`
    );

    const html = toHTML(customHtml, {
      escapeHTML: false,
    });

    return html;
  }, [props.text, props.vars]);

  return (
    <div
      className='message-template-preview break-words'
      dangerouslySetInnerHTML={{
        __html: previewHTML,
      }}
    ></div>
  );
}
