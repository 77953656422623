import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { type ReactNode } from 'react';

import { type DtoProgramRound } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';

export function ProgramRoundEntry(props: {
  round: DtoProgramRound;
  timezone: string;
  cover?: ReactNode;
  action?: ReactNode;
}) {
  const { round, timezone } = props;
  const localTimeZone = useInstance(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const startedAt = round.startedAt ? new Date(round.startedAt) : null;

  return (
    <div
      className='w-full h-26 rounded-xl border border-secondary bg-modal 
    flex items-center gap-4 p-2'
    >
      <div className='w-40 h-22.5 flex-none'>{props.cover}</div>
      <div className='text-sms font-bold line-clamp-2 w-50'>{round.label}</div>
      <div className='flex flex-col gap-1.5 text-[#8C6FFF] w-20'>
        {round.tags?.map((t) => (
          <div key={t.id} className='text-3xs'>
            {t.name}
          </div>
        ))}
      </div>
      <div className='text-sms text-secondary capitalize'>{round.status}</div>
      <div className='ml-auto'></div>
      <div className='flex flex-col gap-1 w-45'>
        <div className='text-sms'>
          {startedAt
            ? format(
                utcToZonedTime(startedAt, timezone),
                'MMMM d, yyyy h:mm aa'
              )
            : 'N/A'}
        </div>
        <div className='text-2xs text-secondary'>
          {startedAt && localTimeZone !== timezone && (
            <>
              <div>Your local timezone: </div>
              {format(
                utcToZonedTime(startedAt, localTimeZone),
                'MMMM d, yyyy h:mm aa'
              )}
            </>
          )}
        </div>
      </div>
      <div>{props.action}</div>
    </div>
  );
}
