import { format } from 'date-fns';
import React, { useMemo } from 'react';

import { EnumsProgramRoundParentType } from '@lp-lib/api-service-client/public';

import placeholder from '../../../assets/img/placeholder/game-cover.png';
import { NotificationType, RoleUtils } from '../../../types';
import { fromMediaDTO } from '../../../utils/api-dto';
import { uuidv4 } from '../../../utils/common';
import { ImagePickPriorityLowToHigh, MediaUtils } from '../../../utils/media';
import { type Action, ActionSheet } from '../../ActionSheet';
import { DragDropList } from '../../common/DragDrop';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ArrowDownIcon, ArrowUpIcon } from '../../icons/Arrows';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { DuplicateIcon } from '../../icons/DuplicateIcon';
import { EditIcon } from '../../icons/EditIcon';
import { EyeIcon } from '../../icons/EyeIcon';
import { MenuIcon } from '../../icons/MenuIcon';
import { useNotificationDataSource } from '../../Notification/Context';
import { SwitcherControlled } from '../../Switcher';
import { useUser } from '../../UserContext';
import { type WaterCoolerTopic } from './types';
import {
  useTriggerWaterCoolerTopicEditorModal,
  useTriggerWaterCoolerTopicPreviewModal,
  type WaterCoolerTopicFormData,
} from './WaterCoolerTopicEditor';

function WaterCoolerTopicActionSheet(props: {
  context: WaterCoolerTopicQueueContext;
  topic: WaterCoolerTopic;
  onPreview: () => void;
  onRemix: () => void;
  onEdit: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
}) {
  const isAdmin = RoleUtils.isAdmin(useUser());
  const isProgramRound =
    props.topic.parentType ===
    EnumsProgramRoundParentType.ProgramRoundParentTypeProgram;

  const actions: Action<string>[] = [];
  actions.push({
    kind: 'button',
    key: 'preview',
    icon: <EyeIcon />,
    text: 'Preview',
    onClick: props.onPreview,
  });
  if (props.context === 'program') {
    actions.push({
      kind: 'button',
      key: 'duplicate',
      icon: <DuplicateIcon />,
      text: 'Duplicate',
      onClick: props.onDuplicate,
    });
  } else {
    actions.push({
      kind: 'button',
      key: 'remix',
      icon: <DuplicateIcon />,
      text: 'Remix',
      onClick: props.onRemix,
      disabled: !isProgramRound,
    });
  }
  actions.push({
    kind: 'button',
    key: 'edit',
    icon: <EditIcon />,
    text: 'Edit',
    onClick: props.onEdit,
    disabled: !isAdmin && isProgramRound,
  });
  actions.push({
    kind: 'button',
    key: 'delete',
    icon: <DeleteIcon />,
    text: 'Delete',
    className: 'text-red-500',
    onClick: props.onDelete,
    disabled: !isAdmin && isProgramRound,
  });

  return <ActionSheet btnSizingClassName='w-7.5 h-7.5' actions={actions} />;
}

function WaterCoolerTopicCard(props: {
  context: WaterCoolerTopicQueueContext;
  topic: WaterCoolerTopic;
  onToggleStatus: (active: boolean) => void;
  onClick: () => void;
  action?: React.ReactNode;
  editable: boolean;
}) {
  const { context, topic, onToggleStatus, onClick, action, editable } = props;

  const coverUrl = useMemo(
    () =>
      MediaUtils.PickMediaUrl(fromMediaDTO(topic.media?.media), {
        priority: ImagePickPriorityLowToHigh,
      }),
    [topic.media?.media]
  );

  return (
    <div
      className='
        w-full bg-modal border border-secondary rounded-xl 
        p-2 pr-5 flex justify-between items-center gap-2
        text-sms text-white hover:bg-lp-gray-003 cursor-pointer
      '
      onClick={onClick}
    >
      <img
        src={coverUrl || placeholder}
        alt='cover'
        className={`object-cover rounded w-40 h-22.5`}
      />
      <div className='w-100 font-bold line-clamp-4'>{topic.text}</div>
      <div className='flex flex-col gap-1.5 text-[#8C6FFF] w-20'>
        {topic.parentType ===
        EnumsProgramRoundParentType.ProgramRoundParentTypeProgram ? (
          topic.tags?.map((t) => (
            <div key={t.id} className='text-3xs'>
              {t.name}
            </div>
          ))
        ) : (
          <div className='text-3xs text-[#ff8f00]'>Custom</div>
        )}
      </div>
      {context === 'programLink' && (
        <div onClick={(e) => e.stopPropagation()}>
          <SwitcherControlled
            name={`water-cooler-topic-${topic.id}-status`}
            className=''
            checked={topic.isActive}
            onChange={(checked) => {
              onToggleStatus(checked);
            }}
            disabled={!editable}
          />
        </div>
      )}
      {context === 'programLink' && (
        <div className='w-30 text-right whitespace-pre-wrap'>
          {topic.sendAt
            ? `${format(topic.sendAt, 'EEE, MMM do')}\n${format(
                topic.sendAt,
                'h:mm a'
              )}`
            : ''}
        </div>
      )}

      {action}
    </div>
  );
}

export type WaterCoolerTopicQueueContext = 'program' | 'programLink';

export function WaterCoolerTopicQueue(props: {
  context: WaterCoolerTopicQueueContext;
  programId: string;
  topics: WaterCoolerTopic[];
  onAdd: (data: WaterCoolerTopicFormData, index: number) => Promise<void>;
  onUpdate: (
    topic: WaterCoolerTopic,
    req: WaterCoolerTopicFormData,
    index: number
  ) => Promise<void>;
  onDelete: (round: WaterCoolerTopic, index: number) => Promise<void>;
  onMove: (from: number, to: number) => Promise<void>;
  onToggleStatus?: (
    topic: WaterCoolerTopic,
    active: boolean,
    index: number
  ) => Promise<void>;
  onShuffle: () => Promise<void>;
  editable?: boolean;
}) {
  const {
    context,
    programId,
    topics,
    onAdd,
    onDelete,
    onUpdate,
    onMove,
    onToggleStatus,
    onShuffle,
    editable = true,
  } = props;

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const triggerTopicEditorModal = useTriggerWaterCoolerTopicEditorModal();
  const triggerTopicPreviewModal = useTriggerWaterCoolerTopicPreviewModal();
  const hideTags = context === 'programLink';

  const { send: sendNotification } = useNotificationDataSource();
  const user = useUser();

  const toast = (message: string) => {
    sendNotification({
      id: uuidv4(),
      toUserClientId: user.id,
      type: NotificationType.WaterCoolerAction,
      createdAt: Date.now(),
      metadata: {
        content: message,
      },
    });
  };

  const handleAdd = () => {
    triggerTopicEditorModal({
      programId,
      header: 'Add Topic',
      onSave: (data) => onAdd(data, 0),
      hideTags,
    });
  };

  const handlePreview = (topic: WaterCoolerTopic) => {
    triggerTopicPreviewModal({
      programId,
      text: topic.text,
      media: topic.media,
    });
  };

  const handleEdit = (topic: WaterCoolerTopic, index: number) => {
    triggerTopicEditorModal({
      header: 'Edit Topic',
      programId,
      defaultValues: topic,
      hideTags,
      onSave: async (data) => {
        await onUpdate(topic, data, index);
        toast('✅ Your topic was updated!');
      },
    });
  };

  const handleDuplicate = async (topic: WaterCoolerTopic, index: number) => {
    triggerTopicEditorModal({
      programId,
      header: 'Duplicate Topic',
      defaultValues: topic,
      hideTags,
      onSave: async (data) => {
        await onAdd(data, index + 1);
        toast('✅ Your topic was duplicated!');
      },
    });
  };

  const handleRemix = async (topic: WaterCoolerTopic, index: number) => {
    triggerTopicEditorModal({
      programId,
      header: 'Remix Topic',
      hideTags,

      defaultValues: {
        ...topic,
        tags: [],
      },
      onSave: async (data) => {
        onToggleStatus?.(topic, false, index);
        await onAdd(data, index);
        toast('✅ Your topic was remixed!');
      },
    });
  };

  const handleDelete = async (topic: WaterCoolerTopic, index: number) => {
    const resp = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='text-white text-center px-5'>
          <div className='w-full text-2xl font-medium'>
            <p>Are you sure you want to delete this topic?</p>
          </div>
          <div className='w-full mt-4 text-sms font-normal'>
            If you delete this topic, it will be lost forever. This action
            cannot be undone.
          </div>
        </div>
      ),
      confirmBtnLabel: 'Delete',
      confirmBtnVariant: 'delete',
      cancelBtnLabel: 'Cancel',
    });
    if (resp.result === 'canceled') return false;
    await onDelete(topic, index);
    toast('✅ Your topic was deleted!');
  };

  const handleToggleStatus = async (
    topic: WaterCoolerTopic,
    active: boolean,
    index: number
  ) => {
    await onToggleStatus?.(topic, active, index);
    toast(`✅ Your topic was ${active ? 'activated' : 'deactivated'}!`);
  };

  const handleMove = async (from: number, to: number) => {
    await onMove(from, to);
    toast('✅ Your topics were reordered!');
  };

  const handleShuffle = async () => {
    await onShuffle();
    toast('✅ Your topics were shuffled!');
  };

  return (
    <section className='w-full'>
      <header className='w-full flex justify-between items-center'>
        <div className='flex items-center gap-2'>
          <p className='font-bold'>Topic Queue </p>
          {editable && (
            <button
              type='button'
              className='btn text-secondary text-sms'
              onClick={handleShuffle}
            >
              Shuffle Topics
            </button>
          )}
        </div>

        {editable && (
          <button
            type='button'
            className='btn font-bold text-primary'
            onClick={handleAdd}
          >
            + Add New Topic
          </button>
        )}
      </header>
      <main className='mt-5 flex flex-col gap-2.5'>
        <DragDropList
          type='program-topics'
          items={topics}
          onMove={handleMove}
          render={({ item, index, drag, ref, style }) => {
            return (
              <div
                key={item.id}
                ref={ref}
                className='flex flex-row justify-between items-center gap-5'
                style={style}
              >
                {editable && (
                  <div className='flex flex-col gap-1 text-white'>
                    <button
                      type='button'
                      className='btn'
                      onClick={() => handleMove(index, 0)}
                      title='Move to top'
                    >
                      <ArrowUpIcon className='w-3.5 h-3.5 fill-current' />
                    </button>

                    <div
                      className='cursor-move'
                      ref={drag}
                      title='Drag to reorder'
                    >
                      <MenuIcon className='w-3.5 h-3.5 fill-current' />
                    </div>

                    <button
                      type='button'
                      className='btn'
                      onClick={() => handleMove(index, topics.length - 1)}
                      title='Move to bottom'
                    >
                      <ArrowDownIcon className='w-3.5 h-3.5 fill-current' />
                    </button>
                  </div>
                )}

                <WaterCoolerTopicCard
                  context={context}
                  topic={item}
                  onClick={() => handlePreview(item)}
                  onToggleStatus={(active) =>
                    handleToggleStatus(item, active, index)
                  }
                  editable={editable}
                  action={
                    editable ? (
                      <WaterCoolerTopicActionSheet
                        topic={item}
                        context={context}
                        onPreview={() => handlePreview(item)}
                        onRemix={() => handleRemix(item, index)}
                        onEdit={() => handleEdit(item, index)}
                        onDuplicate={() => handleDuplicate(item, index)}
                        onDelete={() => handleDelete(item, index)}
                      />
                    ) : null
                  }
                />
              </div>
            );
          }}
        />

        {topics.length === 0 && (
          <div className='ml-7.5 mt-8 flex items-center gap-5'>
            <p className='text-4.5xl'>👻</p>
            <div className='flex flex-col'>
              <p className='font-bold text-white'>
                You don’t have any Topics in your queue
              </p>
              <p className='text-sms text-icon-gray'>
                {context === 'programLink'
                  ? `Please select categories or add new topics.`
                  : 'Please add new topics.'}
              </p>
            </div>
          </div>
        )}
      </main>
    </section>
  );
}
