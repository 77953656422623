import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';

import { type EnumsMessageCampaignType } from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';

export function useMessageLogics(
  campaignType: EnumsMessageCampaignType,
  campaignId: string
) {
  return useSWR(
    [campaignType, campaignId, '/messages/logics'],
    async () =>
      (await apiService.message.getLogicsByCampaign(campaignType, campaignId))
        .data.logics
  );
}

export function useRevalidateMessageLogics(campaignId: string) {
  const { mutate } = useSWRConfig();
  return useCallback(
    () => mutate([campaignId, '/messages/logics']),
    [campaignId, mutate]
  );
}
