import { type ReactNode, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';

import {
  type DtoTag,
  EnumsMediaScene,
  EnumsMessageCampaignType,
  EnumsMessageLogicStatus,
  EnumsMessageTarget,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { fromMediaDTO, toMediaDTO } from '../../../utils/api-dto';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { Loading } from '../../Loading';
import { MediaUploader } from '../../MediaUploader/MediaUploader';
import { MessageTemplatePreview, useMessageLogics } from '../../Message';
import { SlackMarkdownHint } from '../../Message/MessageTemplateText';
import { MessageLogicUtils } from '../../Message/utils';
import { TagPicker } from '../../Tagging';
import { type WaterCoolerTopic } from './types';

function TextField() {
  return (
    <Controller
      name='text'
      render={({ field, formState }) => (
        <div className='w-full'>
          <div className='mb-2 font-bold'>Topic</div>
          <textarea
            {...field}
            className={`${
              formState.errors.text ? 'field-error' : 'field'
            } h-36 bg-main-layer p-5 mb-0 scrollbar`}
            maxLength={2000}
            minLength={1}
          ></textarea>
          <SlackMarkdownHint className='mt-1' />
        </div>
      )}
      rules={{ required: true, minLength: 1, maxLength: 2000 }}
    />
  );
}

function MediaField(props: { setIsUploading: (isUploading: boolean) => void }) {
  const { setIsUploading } = props;
  return (
    <Controller
      name='media'
      render={({ field: { value, onChange } }) => (
        <div className='w-full'>
          <div className='mb-2 font-bold'>Media</div>
          <MediaUploader
            video={true}
            scene={EnumsMediaScene.MediaSceneProgram}
            media={fromMediaDTO(value?.media)}
            onUploadStart={() => setIsUploading(true)}
            onUploadSuccess={(media) => {
              onChange({
                mediaData: { id: media.id },
                media: toMediaDTO(media),
              });
              setIsUploading(false);
            }}
            onUploadFailed={() => setIsUploading(false)}
            onMediaDelete={() => {
              onChange({
                mediaData: null,
                media: null,
              });
            }}
            width='w-full max-w-72'
          />
        </div>
      )}
    />
  );
}

export interface WaterCoolerTopicPreviewProps {
  programId: string;
  text: string;
  media: WaterCoolerTopic['media'] | null;
  className?: string;
}

function WaterCoolerTopicPreview(props: WaterCoolerTopicPreviewProps) {
  const { data: logics } = useMessageLogics(
    EnumsMessageCampaignType.MessageCampaignTypeProgramRound,
    props.programId
  );
  const template = useMemo(() => {
    return MessageLogicUtils.Sort(logics).find(
      (l) =>
        l.status === EnumsMessageLogicStatus.MessageLogicStatusActive &&
        l.target === EnumsMessageTarget.MessageTargetChannel
    )?.template;
  }, [logics]);

  if (!template) return <Loading />;

  return (
    <MessageTemplatePreview
      template={template}
      vars={{
        topicText: props.text || '',
      }}
      mediaVars={{
        topicMedia: fromMediaDTO(props.media?.media),
      }}
      className={props.className}
    />
  );
}

function PreviewField(props: { programId: string }) {
  const { watch } = useFormContext<WaterCoolerTopicFormData>();
  const text = watch('text');
  const media = watch('media');

  return (
    <div className='w-full'>
      <div className='mb-2 font-bold'>Preview</div>
      <WaterCoolerTopicPreview
        programId={props.programId}
        text={text}
        media={media}
        className='min-h-48'
      />
    </div>
  );
}

function TagsField() {
  return (
    <Controller
      name={`tags`}
      render={({ field }) => (
        <div className='w-full'>
          <div className='mb-2 font-bold'>Categories</div>
          <TagPicker
            onChange={(tags) => {
              field.onChange(tags);
            }}
            tags={field.value}
            creatable
            multi={true}
            placeholder='Add existing or create new Categories'
          />
        </div>
      )}
    />
  );
}

export type WaterCoolerTopicFormData = {
  text: string;
  media?: WaterCoolerTopic['media'];
  tags: DtoTag[];
};

type WaterCoolerTopicEditorModalProps =
  TriggerWaterCoolerTopicEditorModalProps & {
    onCancel: () => void;
  };

function WaterCoolerTopicEditorModal(props: WaterCoolerTopicEditorModalProps) {
  const { defaultValues, hideTags, header, programId } = props;
  const form = useForm<WaterCoolerTopicFormData>({
    defaultValues: defaultValues || {
      text: '',
      tags: [],
    },
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleSave = form.handleSubmit((data) => {
    props.onSave(data);
  });

  return (
    <ModalWrapper
      containerClassName='w-240'
      borderStyle='gray'
      onClose={props.onCancel}
    >
      <FormProvider {...form}>
        <section className='p-7.5'>
          <header className='text-2xl font-medium'>
            {header || 'Edit Topic'}
          </header>
          <main className='mt-5 w-full flex flex-col gap-5'>
            <section>
              {!hideTags && (
                <div className='w-1/2'>
                  <TagsField />
                </div>
              )}
            </section>

            <section className='flex gap-4'>
              <div className='w-1/2 flex flex-col gap-5'>
                <TextField />
                <MediaField setIsUploading={setIsUploading} />
              </div>
              <div className='w-1/2'>
                <PreviewField programId={programId} />
              </div>
            </section>
          </main>
          <footer className='mt-5 flex justify-end items-center gap-10'>
            <button type='button' className='btn' onClick={props.onCancel}>
              Cancel
            </button>
            <button
              type='button'
              className='btn-primary h-10 px-4 min-w-30'
              onClick={handleSave}
              disabled={isUploading}
            >
              Save
            </button>
          </footer>
        </section>
      </FormProvider>
    </ModalWrapper>
  );
}

export interface TriggerWaterCoolerTopicEditorModalProps {
  programId: string;
  defaultValues?: WaterCoolerTopicFormData;
  header?: ReactNode;
  hideTags?: boolean;

  onSave: (data: WaterCoolerTopicFormData) => void;
}

export function useTriggerWaterCoolerTopicEditorModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback((props: TriggerWaterCoolerTopicEditorModalProps) => {
    triggerModal({
      kind: 'custom',

      element: (p) => (
        <WaterCoolerTopicEditorModal
          {...props}
          onCancel={() => {
            p.internalOnCancel();
          }}
          onSave={(template) => {
            props.onSave(template);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  });
}

export function useTriggerWaterCoolerTopicPreviewModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback((props: WaterCoolerTopicPreviewProps) => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-128'
          borderStyle='gray'
          onClose={p.internalOnCancel}
        >
          <div className='w-full px-7.5 py-6'>
            <WaterCoolerTopicPreview {...props} />

            <div className='mt-3 flex justify-end'>
              <button
                type='button'
                className='btn-secondary w-30 h-10'
                onClick={p.internalOnCancel}
              >
                Close
              </button>
            </div>
          </div>
        </ModalWrapper>
      ),
    });
  });
}
