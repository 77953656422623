import uniq from 'lodash/uniq';
import { useMemo, useState } from 'react';

import { type DtoTag } from '@lp-lib/api-service-client/public';

import { FilledCheckIcon } from '../icons/CheckIcon';
import { PlusIcon } from '../icons/PlusIcon';
import { XBoldIcon } from '../icons/XIcon';

export function ProgramCategoriesEditor(props: {
  programName: string;
  tags: DtoTag[];
  selectedTagIds: number[];
  onChange: (selectedTagIds: number[]) => void;
}) {
  const { programName, tags, selectedTagIds } = props;

  return (
    <div className='flex flex-col gap-6'>
      <header className='text-2xl font-medium'>
        {programName ?? ''} Categories
      </header>
      <div className='flex items-center gap-2'>
        <span className='font-bold'>Select Default Categories</span>
        <span className='text-3xs font-medium text-secondary'>
          The categories selected here will determine the default for the user
        </span>
      </div>
      <div className='flex items-center gap-4 flex-wrap'>
        {tags.map((t) => (
          <label
            key={t.id}
            className='flex items-center justify-between flex-shrink-0 w-40 h-10 
            rounded-xl border border-secondary bg-modal text-sms px-2'
          >
            <div>{t.name}</div>
            <input
              type='checkbox'
              className='checkbox-dark'
              checked={selectedTagIds.includes(t.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  props.onChange([...selectedTagIds, t.id]);
                } else {
                  props.onChange(selectedTagIds.filter((id) => id !== t.id));
                }
              }}
            />
          </label>
        ))}
        {tags.length === 0 && (
          <div className='w-full text-sms text-secondary'>No Categories</div>
        )}
      </div>
    </div>
  );
}

const SHOW_LESS_THRESHOLD = 15;

export function ProgramCategoriesSubscriber(props: {
  tags: DtoTag[];
  selectedTagIds: number[];
  onAdd: (tag: DtoTag) => void;
  onRemove: (tag: DtoTag) => void;
  hint?: string;
  disabled?: boolean;
}) {
  const { tags, onAdd, onRemove } = props;

  const selectedTagIds = useMemo(
    () => uniq(props.selectedTagIds),
    [props.selectedTagIds]
  );
  const availableTags = useMemo(() => {
    const results: DtoTag[] = [];
    const tagMap = new Map(tags.map((t) => [t.id, t]));
    for (const tagId of selectedTagIds) {
      if (tagMap.has(tagId)) tagMap.delete(tagId);
    }
    for (const tag of tags) {
      if (!tagMap.has(tag.id)) continue;
      results.push(tag);
      tagMap.delete(tag.id);
    }
    return results.sort((a, b) => a.name.localeCompare(b.name));
  }, [selectedTagIds, tags]);

  const selectedTags = useMemo(() => {
    const results: DtoTag[] = [];
    for (const tagId of selectedTagIds) {
      const tag = tags.find((t) => t.id === tagId);
      if (!tag) continue;
      results.push(tag);
    }
    return results;
  }, [selectedTagIds, tags]);

  const [showLess, setShowLess] = useState(
    availableTags.length > SHOW_LESS_THRESHOLD
  );
  const visibleTags = showLess
    ? availableTags.slice(0, SHOW_LESS_THRESHOLD - 1)
    : availableTags;

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex items-center gap-2'>
        <span className='font-bold'>Categories</span>
        <span className='text-3xs text-icon-gray'>
          {props.hint ?? 'Select and subscribe to the categories'}
        </span>
      </div>
      <div className='flex gap-3'>
        <div className='text-sms mt-px flex-shrink-0'>
          Available Categories:
        </div>
        <div className='flex gap-2 flex-wrap'>
          {visibleTags.map((t) => (
            <button
              type='button'
              key={t.id}
              className='btn flex items-center justify-between flex-shrink-0 w-40 h-10 
              rounded-xl border border-secondary bg-modal text-sms px-3'
              disabled={props.disabled || selectedTagIds.includes(t.id)}
              onClick={() => onAdd(t)}
            >
              {t.name}
              {selectedTagIds.includes(t.id) ? (
                <FilledCheckIcon className='w-4 h-4 fill-current' />
              ) : (
                <PlusIcon className='w-3.5 h-3.5 fill-current' />
              )}
            </button>
          ))}
          {availableTags.length > SHOW_LESS_THRESHOLD && (
            <button
              type='button'
              className='btn text-sms text-icon-gray'
              onClick={() => setShowLess(!showLess)}
            >
              See {showLess ? 'More' : 'Less'}
            </button>
          )}
        </div>
      </div>
      <div className='flex gap-3'>
        <div className='text-sms mt-px flex-shrink-0'>Selected Categories:</div>
        <div className='flex gap-2 flex-wrap'>
          {selectedTags.map((t) => (
            <button
              type='button'
              key={t.id}
              className='btn flex items-center justify-between flex-shrink-0 w-40 h-10 
              rounded-xl border border-secondary bg-[#8C6FFF] text-sms px-3'
              onClick={() => onRemove(t)}
              disabled={props.disabled}
            >
              {t.name}
              <XBoldIcon className='w-3.5 h-3.5 fill-current' />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
