import { useMemo } from 'react';
import { match } from 'ts-pattern';

import {
  type DtoMessageLogicV2,
  EnumsMessageLogicStatus,
  type EnumsMessageTarget,
  EnumsMessageTriggerType,
  type ModelsMessageCondition,
  type ModelsMessageTemplateV2,
  type ModelsMessageTrigger,
} from '@lp-lib/api-service-client/public';

import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { ChatBubbleIcon } from '../icons/Chat/ChatBubbleIcon';
import { useMessageLogics } from './hooks';
import { type MessageCampaign, type MessageVars } from './MessageCampaign';
import { MESSAGE_TARGET_CONFIG } from './MessageTarget';
import { MessageTemplatePreview } from './MessageTemplate';
import { MESSAGE_TRIGGER_EVENT_CONFIG } from './MessageTrigger';
import { MessageTriggerUtils } from './utils';

export function MessageTargetCell(props: { target: EnumsMessageTarget }) {
  return (
    <div className='w-40 text-sms font-bold'>
      To {MESSAGE_TARGET_CONFIG[props.target]?.label}
    </div>
  );
}

export function MessageConditionAndTemplateCell(props: {
  condition?: ModelsMessageCondition;
  template: ModelsMessageTemplateV2;
}) {
  const { condition, template } = props;
  return (
    <div className='w-100 min-h-14 flex flex-col justify-center'>
      {condition?.expression && (
        <p className='text-sms text-[#8C6FFF]'>if ({condition.expression})</p>
      )}
      <p className='text-sms line-clamp-3 whitespace-pre-wrap'>
        {template.text}
      </p>
    </div>
  );
}

export function MessageTriggerCell(props: {
  trigger: ModelsMessageTrigger;
}): JSX.Element | null {
  const { trigger } = props;

  const triggerType =
    trigger.type || EnumsMessageTriggerType.MessageTriggerTypeTimeOffset;

  return (
    <div className='w-40 text-sms text-right whitespace-pre-wrap'>
      {match(triggerType)
        .with(EnumsMessageTriggerType.MessageTriggerTypeTimeOffset, () => {
          if (!trigger.timeOffset) return null;
          const absoluteTimeStr = MessageTriggerUtils.GetAbsoluteTimeLabel(
            trigger.timeOffset.triggerTime
          );
          const relativeTimeStr = MessageTriggerUtils.GetTimeOffsetLabel(
            trigger.timeOffset
          );
          return absoluteTimeStr ? (
            <p title={relativeTimeStr}>{absoluteTimeStr}</p>
          ) : (
            <p>{relativeTimeStr}</p>
          );
        })
        .with(EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset, () => {
          if (!trigger.weekdaysOffset) return null;
          const absoluteTimeStr = MessageTriggerUtils.GetAbsoluteTimeLabel(
            trigger.weekdaysOffset.triggerTime
          );
          const relativeTimeStr = MessageTriggerUtils.GetWeekdaysOffsetLabel(
            trigger.weekdaysOffset
          );
          return absoluteTimeStr ? (
            <p title={relativeTimeStr}>{absoluteTimeStr}</p>
          ) : (
            <p>{relativeTimeStr}</p>
          );
        })
        .with(EnumsMessageTriggerType.MessageTriggerTypeEventDriven, () => {
          if (!trigger.eventName) return null;
          return MESSAGE_TRIGGER_EVENT_CONFIG[trigger.eventName].label;
        })
        .exhaustive()}
    </div>
  );
}

function TemplatePreviewModal(props: {
  logic: DtoMessageLogicV2;
  vars: MessageVars;
  onClose: () => void;
}) {
  return (
    <ModalWrapper
      containerClassName='w-156'
      borderStyle='white'
      onClose={props.onClose}
    >
      <div className='w-full px-7.5 py-4'>
        <header className='text-2xl font-medium'>
          To {MESSAGE_TARGET_CONFIG[props.logic.target].label}
        </header>

        <MessageTemplatePreview
          template={props.logic.template}
          vars={props.vars.text}
          mediaVars={props.vars.media}
          className='mt-3 bg-modal'
        />

        <div className='mt-3 flex justify-end'>
          <button
            type='button'
            className='btn-secondary w-30 h-10'
            onClick={props.onClose}
          >
            Close
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

function MessageLogicCard(props: {
  logic: DtoMessageLogicV2;
  campaign: MessageCampaign;
}) {
  const { logic, campaign } = props;

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const vars = useMemo(
    () => campaign.vars(logic.trigger, logic.target, logic.tags),
    [campaign, logic.target, logic.trigger, logic.tags]
  );

  const handlePreview = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <TemplatePreviewModal
          logic={logic}
          vars={vars}
          onClose={p.internalOnCancel}
        />
      ),
    });
  };

  return (
    <div
      className='w-full bg-modal rounded-xl border border-secondary
        px-5 py-2.5 flex justify-between items-center gap-3
      '
    >
      <MessageTargetCell target={logic.target} />

      <MessageConditionAndTemplateCell template={logic.template} />

      <MessageTriggerCell trigger={logic.trigger} />

      <button
        type='button'
        onClick={handlePreview}
        className='flex-none btn hover:text-primary'
      >
        Preview
      </button>
    </div>
  );
}

export function MessageLogicList(props: { campaign: MessageCampaign }) {
  const { campaign } = props;

  const { data: logics } = useMessageLogics(campaign.type, campaign.id);

  const activeLogics = useMemo(
    () =>
      logics?.filter(
        (l) => l.status === EnumsMessageLogicStatus.MessageLogicStatusActive
      ),
    [logics]
  );

  if (!activeLogics) return null;

  return (
    <div className='w-full'>
      <div className='text-2xl font-medium flex items-center gap-2.5'>
        <ChatBubbleIcon className='w-7.5 h-7.5 fill-current' />
        <div>Messages</div>
      </div>
      <div className='mt-2 text-3xs font-medium text-icon-gray'>
        These messages will be sent out to the designated recipient at the
        provided time via Slack.
      </div>
      <div className='mt-5 flex flex-col gap-2.5'>
        {activeLogics?.map((logic) => (
          <MessageLogicCard key={logic.id} logic={logic} campaign={campaign} />
        ))}
      </div>
    </div>
  );
}
