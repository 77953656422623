import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';

import {
  type DtoChannel,
  type DtoChannelProgramLink,
  EnumsMessageCampaignType,
  EnumsMessageTarget,
  EnumsMessageTriggerType,
  type ModelsMessageTrigger,
  type ModelsProgramBasicSettings,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { type CalendarProgramRound } from '../../../types/program';
import { fromMediaDTO } from '../../../utils/api-dto';
import {
  type MessageCampaign,
  type MessageTriggerConfig,
  type MessageVars,
} from '../../Message';
import { useUser } from '../../UserContext';

export function useMessageCampaignProgramRoundCalendar(props: {
  programBasicSettings?: ModelsProgramBasicSettings | null;
  round: CalendarProgramRound;
  programLink?: DtoChannelProgramLink;
  channel?: DtoChannel;
}): MessageCampaign {
  const { round, programBasicSettings, programLink } = props;
  const user = useUser();

  const trigger: MessageTriggerConfig = useMemo(
    () => ({
      types: [
        EnumsMessageTriggerType.MessageTriggerTypeTimeOffset,
        EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset,
      ],
    }),
    []
  );

  const targets = useCallback(() => {
    return [EnumsMessageTarget.MessageTargetChannelMembers];
  }, []);

  const tags = useLiveCallback(
    (_trigger: ModelsMessageTrigger, _target: EnumsMessageTarget) => {
      return [];
    }
  );

  const vars = useCallback(() => {
    const vars: MessageVars = {
      text: {
        recipientName: `<@${user.username}>`,
        programLongDescription: programBasicSettings?.longDescription || '',
        programDetailsLink: programLink?.id
          ? `${window.origin}/programs/${programLink.id}/details`
          : window.origin,
        programAdminLink: programLink?.id
          ? `${window.origin}/programs/${programLink.id}/admin`
          : window.origin,
        channelName: '@lunapark',
        roundName: round.label,
        roundStartDate: format(
          round.startedAt ? new Date(round.startedAt) : new Date(),
          'EEE, MMM do'
        ),
      },
      media: {},
      condition: {
        containsGamePack: false,
        hasGameScheduled: false,
      },
    };
    if (round.extensions?.gamePack) {
      vars.text.gamePackName = round.extensions.gamePack.name;
      vars.text.gamePackScheduleLink = `${window.origin}/events/create?game-pack-id=${round.extensions.gamePack.id}`;
      vars.media.gamePackCover = fromMediaDTO(round.extensions.gamePack.cover);
      vars.condition.containsGamePack = true;
    }
    return vars;
  }, [
    programBasicSettings?.longDescription,
    programLink?.id,
    round.extensions?.gamePack,
    round.label,
    round.startedAt,
    user.username,
  ]);

  return useMemo(
    () => ({
      type: EnumsMessageCampaignType.MessageCampaignTypeProgramRound,
      id: round.id,
      trigger,
      targets,
      tags,
      vars,
    }),
    [round.id, targets, trigger, tags, vars]
  );
}
