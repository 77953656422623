import { type ReactNode } from 'react';

import { type CalendarProgramRound } from '../../../types/program';
import { fromDTOGamePack } from '../../../utils/api-dto';
import { GamePackCover } from '../../Game/Utilities';
import { ProgramRoundEntry } from '../ProgramRoundEntry';

export function CalendarProgramRoundEntry(props: {
  round: CalendarProgramRound;
  timezone: string;
  action?: ReactNode;
}) {
  const { round } = props;

  return (
    <ProgramRoundEntry
      {...props}
      cover={
        <GamePackCover pack={fromDTOGamePack(round.extensions?.gamePack)} />
      }
    />
  );
}
